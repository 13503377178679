import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectTruckDialogComponent } from './select-truck-dialog/select-truck-dialog.component';
import { NgxGpAutocompleteDirective } from '@angular-magic/ngx-gp-autocomplete';
import { Store } from '@ngxs/store';
import { ApplyStateModel } from 'projects/web/src/app/states/apply/apply.model';
import { AddApplyNow } from 'projects/web/src/app/states/apply/apply.actions';
import { CommonService } from 'projects/web/src/app/services/common.service';
import { Truck } from '../../../../../dto/truck';
import { GetTruckById } from 'projects/web/src/app/states/truck/truck.action';

@Component({
  selector: 'app-apply-now-submit',
  templateUrl: './apply-now-submit.component.html',
  styleUrls: ['./apply-now-submit.component.css'],
})
export class ApplyNowSubmitComponent {

  data: FormGroup;
  planingOnHoulingList: { key: string; value: string }[] = [
    { key: 'dryvan', value: 'Dry Van' },
    { key: 'reefer', value: 'Reefer' },
    { key: 'flatbed', value: 'Flatbed' },
    { key: 'specialized', value: 'Specialized' },
    { key: 'Other', value: 'Other' }
  ]
  statelist: { key: string; value: string }[] = [
    { key: 'AL', value: 'AL' },
    { key: 'AK', value: 'AK' },
    { key: 'AZ', value: 'AZ' },
    { key: 'AR', value: 'AR' },
    { key: 'CA', value: 'CA' },
    { key: 'CO', value: 'CO' },
    { key: 'CT', value: 'CT' },
    { key: 'DE', value: 'DE' },
    { key: 'FL', value: 'FL' },
    { key: 'GA', value: 'GA' },
    { key: 'HI', value: 'HI' },
    { key: 'ID', value: 'ID' },
    { key: 'IL', value: 'IL' },
    { key: 'IN', value: 'IN' },
    { key: 'IA', value: 'IA' },
    { key: 'KS', value: 'KS' },
    { key: 'KY', value: 'KY' },
    { key: 'LA', value: 'LA' },
    { key: 'ME', value: 'ME' },
    { key: 'MD', value: 'MD' },
    { key: 'MA', value: 'MA' },
    { key: 'MI', value: 'MI' },
    { key: 'MN', value: 'MN' },
    { key: 'MS', value: 'MS' },
    { key: 'MO', value: 'MO' },
    { key: 'MT', value: 'MT' },
    { key: 'NE', value: 'NE' },
    { key: 'NV', value: 'NV' },
    { key: 'NH', value: 'NH' },
    { key: 'NJ', value: 'NJ' },
    { key: 'NM', value: 'NM' },
    { key: 'NY', value: 'NY' },
    { key: 'NC', value: 'NC' },
    { key: 'ND', value: 'ND' },
    { key: 'OH', value: 'OH' },
    { key: 'OK', value: 'OK' },
    { key: 'OR', value: 'OR' },
    { key: 'PA', value: 'PA' },
    { key: 'RI', value: 'RI' },
    { key: 'SC', value: 'SC' },
    { key: 'SD', value: 'SD' },
    { key: 'TN', value: 'TN' },
    { key: 'TX', value: 'TX' },
    { key: 'UT', value: 'UT' },
    { key: 'VT', value: 'VT' },
    { key: 'VA', value: 'VA' },
    { key: 'WA', value: 'WA' },
    { key: 'WV', value: 'WV' },
    { key: 'WI', value: 'WI' },
    { key: 'WY', value: 'WY' },
  ];
  teamDriveList: { key: string; value: string }[] = [
    { key: 'yes', value: 'Yes' },
    { key: 'no', value: 'No' },
  ]
  isDisplayInvalidMsg: boolean = false;
  isReviewMsg: boolean = false;
  isCarrierName: boolean = false;
  isFamilyFriend: boolean = false;
  applyNowData: ApplyStateModel = new ApplyStateModel();
  maskPhonePattern = '(000)000-0000';
  maskSsnPattern = '000-00-0000';
  maskDatePattern = '00/00/0000';
  maxDate = new Date(); // Restrict to today's date or earlier
  dateControl: FormControl;
  marketingSource: string = '';

  truckData: any = <Truck>{};
  isTruckDetailEnable: boolean = false;
  leadId: any;
  truckId: number = 0;

  utm_source: any;
  utm_medium: any;
  utm_campaign: any;
  utm_content: any;
  utm_term: any;

  btnLoading: boolean = false;

  constructor(
    private store: Store,
    private router: Router,
    private spinner: NgxUiLoaderService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.commonService.sendUpdate('75%');
    //this.spinner.startLoader("master");

  }

  ngOnInit(): void {

    this.loadFormControls();

    let url = window.location.href;
    let id = this.commonService.getUrlParameter('id', url);
    this.utm_source = this.commonService.getUrlParameter('utm_source', url);
    this.utm_medium = this.commonService.getUrlParameter('utm_medium', url);
    this.utm_campaign = this.commonService.getUrlParameter('utm_campaign', url);
    this.utm_content = this.commonService.getUrlParameter('utm_content', url);
    this.utm_term = this.commonService.getUrlParameter('utm_term', url);

    if (id != null && id != undefined) {
      this.truckId = parseInt(id);
      this.store.dispatch(new GetTruckById(this.truckId)).subscribe(
        (response: any) => {
          this.truckData = response.null.truckResponse;
          this.isTruckDetailEnable = true;
        },
        (error: any) => {
          this.commonService.openSnackBar('A technical error has occurred. Please reach out to the OTR Team for assistance.', '', 'error');
        }
      );
    }
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(SelectTruckDialogComponent, {
      width: '80%',
      autoFocus: false,
      enterAnimationDuration: '500ms',
      exitAnimationDuration: '1000ms',
      //data: { name: this.dName, animal: this.dAnimal },
      data: {
        id: 2,
        name: '2018 PETERBILT 579 - SLEEPER TRUCK',
        miles: '300',
        amount: '100',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');

      //this.dAnimal = result;
      this.data.patchValue({
        truckId: result.name,
        // formControlName2: myValue2 (can be omitted)
      });
    });
  }

  routerComment() {
    this.router.navigate(['/apply-now/thank-you'], {
      //skipLocationChange: true,
    });
  }

  loadFormControls() {

    this.dateControl = new FormControl(null, [Validators.required]);

    this.data = new FormGroup({
      cdlLength: new FormControl(''),
      source: new FormControl(''),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      address1: new FormControl('', [Validators.required]),
      //streetAddress: new FormControl('', [Validators.required]),
      address2: new FormControl(''),
      state: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      postalcode: new FormControl('', [Validators.required, Validators.pattern('^[0-9-]+$'),]),
      mobilePhone: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$'), Validators.maxLength(10), Validators.minLength(10)]),
      email: new FormControl('', [Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'), Validators.required]),
      dateOfBirth: this.dateControl,//new FormControl<Date>(new Date(), [Validators.required]),
      ssn: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$'), Validators.maxLength(9), Validators.minLength(9),]),
      truckId: new FormControl('0'),
      planningOnHauling: new FormControl(''),
      teamDrive: new FormControl('', [Validators.required]),
      expectedRevene: new FormControl('', [Validators.max(922337203685477)]),
      expectedMileage: new FormControl('', [Validators.max(2147483647)]),
      marketingSource: new FormControl(''),
      carrierName: new FormControl(''),
      srcCarrierName: new FormControl(''),
      familyFriend: new FormControl(''),
      recaptcha: new FormControl('', [Validators.required]),
    });
  }

  onDateInputChange(event: any) {
    const dateString = event?.target?.value;

    const dateParts = dateString.split('/');
    const month = parseInt(dateParts[0], 10);
    const day = parseInt(dateParts[1], 10);
    const year = parseInt(dateParts[2], 10);
    const dateObject = new Date(year, month - 1, day);
    if (isNaN(dateObject.getTime()))
      this.data.patchValue({ dateOfBirth: null });
    else
      this.data.patchValue({ dateOfBirth: dateObject });
  }

  radioButtonChanged(event: any) {
    this.marketingSource = event.value as string;
    let radioBtnVal = event.value;
    if (radioBtnVal == 'carrier') {
      this.isFamilyFriend = false;
      this.removeRequiredValidation('familyFriend');
      this.isCarrierName = true;
    } else if (radioBtnVal == 'family/friend') {
      this.isCarrierName = false;
      this.removeRequiredValidation('srcCarrierName');
      this.isFamilyFriend = true;
    } else {
      this.isCarrierName = false;
      this.isFamilyFriend = false;

      this.removeRequiredValidation('srcCarrierName');
      this.removeRequiredValidation('familyFriend');
    }
  }

  removeRequiredValidation(controlName: string) {
    // Get the control by name
    const control = this.data.get(controlName);

    // Check if the control exists
    if (control) {
      // Clear validators (remove required validator)
      control.clearValidators();
      control.updateValueAndValidity();
    }
  }

  onSubmit() {
    //this.spinner.start(); 

    if (this.data.invalid) {
      this.isDisplayInvalidMsg = true;
      this.scrollToElement();
      return;
    }

    this.isDisplayInvalidMsg = false;
    this.routerComment();
    // this.spinner.stop();
  }

  @ViewChild('myElement') myElement: any = ElementRef;
  scrollToElement() {
    if (this.myElement && this.myElement.nativeElement) {
      this.myElement.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  getErrorMessage(dataObject: any) {
    if (dataObject != null) {
      if (this.data.get(dataObject)?.hasError('required')) {
        return `You must enter a value`;
      } else if (this.data.get(dataObject)?.hasError('email')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('numberField')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('pattern')) {
        return `You must enter a valid value`;
      } else if (this.data.get(dataObject)?.hasError('maxlength')) {
        return `You must enter a max valid length`;
      } else if (this.data.get(dataObject)?.hasError('minlength')) {
        return `You must enter a min valid length`;
      } else if (this.data.get(dataObject)?.hasError('max')) {
        return `You must enter a max valid number`;
      }
    }
    return '';
  }

  options = {
    // Define your autocomplete options here
    componentRestrictions: {
      country: ['US'],
    },
  };

  @ViewChild('ngxPlaces') placesRef: any = NgxGpAutocompleteDirective;

  public handleAddressChange(place: google.maps.places.PlaceResult) {
    console.log(place);
    // Do some stuff
    let addressfullvalue = place?.formatted_address;
    let addressComponents = place?.address_components as any[];
    if (!addressComponents) addressComponents = [];

    const { street, city, zip, state, fullAddress, shortAddress } =
      this.getAddressDetails(addressComponents);

    // Address fields
    this.data.patchValue({
      address1: shortAddress,
      address2: '',
      state: state,
      postalcode: zip,
      city: city,
    });
  }

  getAddressDetails(addressComponents: any[]) {
    let street = '';
    let city = '';
    let zip = '';
    let state = '';
    let fullAddress = '';
    let shortAddress = '';

    addressComponents.forEach((component) => {
      if (component.types.includes('street_number')) {
        street += component.long_name + ' ';
        shortAddress += component.short_name + ' ';
      } else if (component.types.includes('route')) {
        street += component.long_name;
        shortAddress += component.short_name;
      } else if (
        component.types.includes('locality') ||
        component.types.includes('sublocality') ||
        component.types.includes('sublocality_level_1') ||
        component.types.includes('postal_town')
      ) {
        city = component.long_name;
      } else if (component.types.includes('postal_code')) {
        zip = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        state = component.short_name;
      }

      // Concatenate all components for full address
      fullAddress += component.long_name + ', ';
    });

    return { street, city, zip, state, fullAddress, shortAddress };
  }

  isReview: boolean = true;
  isReadOnly: boolean = false;
  isEditOrSubmitEnable: boolean = false;

  onReviewBtnClick() {
    //this.btnLoading = true;
    this.commonService.startSpinner();
    if (this.data.valid) {

      this.postDataOnRewiew();
      this.isReviewMsg = true;
      this.scrollToElement();

    } else {
      this.data.markAllAsTouched();
      this.isDisplayInvalidMsg = true;
      this.scrollToElement();
      //this.btnLoading = false;
      this.commonService.stopSpinner();
    }
  }

  postDataOnRewiew() {
    let leadId: any;
    let expectedMileage: string = this.data.value?.expectedMileage as string;
    let expectedRevene: string = this.data.value?.expectedRevene as string;

    this.applyNowData = {
      firstName: this.data.value?.firstName || '',
      lastName: this.data.value?.lastName || '',
      mobilePhone: this.data.value?.mobilePhone || '',
      address1: this.data.value?.address1 || '',
      address2: this.data.value?.address2 || '',
      state: this.data.value?.state || '',
      expectedFreightType: this.data.value?.planningOnHauling || '',
      city: this.data.value?.city || '',
      postalcode: this.data.value?.postalcode || '',
      email: this.data.value?.email || '',
      ssn: this.data.value?.ssn || '',
      dateOfBirth: this.data.value?.dateOfBirth == undefined ? null : this.data.value?.dateOfBirth,
      cdlOwnerShip: this.commonService.applyNowData?.cdlOwnerShip == undefined ? false : this.commonService.applyNowData?.cdlOwnerShip,
      cdlLength: this.commonService.applyNowData?.cdlLength == undefined ? false : this.commonService.applyNowData?.cdlLength,
      llcOwnerShip: this.commonService.applyNowData?.llcOwnerShip == undefined ? false : this.commonService.applyNowData?.llcOwnerShip,
      llcLength: this.commonService.applyNowData?.llcLength == undefined ? 0 : this.commonService.applyNowData?.llcLength,
      fleetSize: this.commonService.applyNowData?.fleetSize == undefined ? '' : this.commonService.applyNowData?.fleetSize,
      carrierName: this.data.value?.carrierName || '',
      expectedRevene: parseInt(expectedRevene === "" || expectedRevene === null ? '0' : expectedRevene),
      expectedMileage: parseInt(expectedMileage === "" || expectedMileage === null ? '0' : expectedMileage),
      teamDrive: this.data.value?.teamDrive === "yes" ? true : false,
      truckId: this.truckId,
      marketingSource: this.data.value?.marketingSource || '',
      utmSource: this.utm_source,
      utmMedium: this.utm_medium,
      utmCampaign: this.utm_campaign,
      utmContent: this.utm_content,
      utmTerm: this.utm_term,
      leadId: '',

    } as ApplyStateModel

    try {
      this.store.dispatch(new AddApplyNow(this.applyNowData)).subscribe(
        (response: any) => {

          this.leadId = response.applyNow.applyNow.leadId;
          this.commonService.leadId = this.leadId,
            this.isDisplayInvalidMsg = false;
          this.scrollToElement();
          this.isReadOnly = true;
          this.data?.get('dateOfBirth')?.disable();
          this.data?.get('address1')?.disable();
          this.data?.get('state')?.disable();
          this.data?.get('teamDrive')?.disable();
          this.data?.get('planningOnHauling')?.disable();
          this.isReview = false;
          this.isEditOrSubmitEnable = true;
          this.commonService.stopSpinner();
        },
        (error: any) => {

          //this.commonService.openSnackBar(error.message, '', 'error');
          this.commonService.openSnackBar('A technical error has occurred. Please reach out to the OTR Team for assistance.', '', 'error');
          this.commonService.stopSpinner();
        }
      );


    } catch (error: any) {
      this.commonService.openSnackBar(error.message, '', 'error');
      this.commonService.stopSpinner();
    }

    return leadId;

  }

  onEditBtnClick() {
    this.scrollToElement();
    this.isReadOnly = false;
    this.data?.get('dateOfBirth')?.enable();
    this.data?.get('address1')?.enable();
    this.data?.get('state')?.enable();
    this.data?.get('teamDrive')?.enable();
    this.data?.get('planningOnHauling')?.enable();
    this.isEditOrSubmitEnable = false;
    this.isReview = true;
  }
}
